<template>
  <div class="home" id="welcome">
    <section class="home__welcome">
      <div class="home__background-video">
        <video src="../assets/background__video.mp4" loop muted autoplay></video>
      </div>
      <div class="home__welcome-container content-container">
        <div class="home__welcome-top">
          <h1>{{ $t('pages.home.welcome.title') }}</h1>
          <p>{{ $t('pages.home.welcome.description') }}</p>

          <div class="home__welcome-top__image">
            <ImageApp
                :path="'steak.png'"
                :alt="''"
            />
          </div>
        </div>

        <div class="home__welcome-bottom">
          <div class="home__welcome-bottom__image">
            <ImageApp
                :path="'steak-2.png'"
                :alt="''"
            />
          </div>

          <div class="home__welcome-bottom__block">
            <p>{{ $t('pages.home.welcome.paragraph') }}</p>

            <div class="home__welcome-button" v-on:click="modalWindow = !modalWindow">{{
                $t('pages.home.welcome.button')
              }}
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="home__information">
      <div class="home__information-container content-container">
        <div class="home__information-list">
          <div class="home__information-item">
            <div class="home__information-text">
              <h3>{{ $t('pages.home.information.global-meat-traders.title') }}</h3>
              <p>{{ $t('pages.home.information.global-meat-traders.description') }}</p>
            </div>

            <div class="home__information-image">
              <ImageApp
                  :path="'home__information-item__one.png'"
                  :alt="$t('pages.home.information.global-meat-traders.title')"
              />
            </div>
          </div>

          <div class="home__information-item">
            <div class="home__information-text">
              <h3>{{ $t('pages.home.information.global-meat-traders-mission.title') }}</h3>
              <p>{{ $t('pages.home.information.global-meat-traders-mission.description') }}</p>
            </div>

            <div class="home__information-image">
              <ImageApp
                  :path="'home__information-item__two.png'"
                  :alt="$t('pages.home.information.global-meat-traders-mission.title')"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="home__information-background">
        <div class="home__information-background__one">
          <ImageApp
              :path="'bone.png'"
              :alt="'bone'"
          />
        </div>

        <div class="home__information-background__two">
          <ImageApp
              :path="'village.png'"
              :alt="'village'"
          />
        </div>

        <div class="home__information-background__three">
          <ImageApp
              :path="'beef-leg.png'"
              :alt="'beef leg'"
          />
        </div>

        <div class="home__information-background__four">
          <ImageApp
              :path="'pork-steak.png'"
              :alt="'pork steak'"
          />
        </div>

        <div class="home__information-background__five">
          <ImageApp
              :path="'beef-steak.png'"
              :alt="'beef steak'"
          />
        </div>
      </div>
    </section>

    <section class="home__products">
      <div class="home__products-container content-container">
        <h2>{{ $t('pages.home.products.title') }}</h2>

        <div class="home__products-list">
          <div class="home__products-item">
            <div class="home__products-image">
              <router-link :to="$i18nRoute({ name: 'Products' })">
                <ImageApp
                    :path="'poultry.png'"
                    :alt="$t('poultry')"
                />
              </router-link>
            </div>

            <div class="home__products-text">
              <div class="home__products-icon">
                <span class="icon icon__poultry"></span>
              </div>
              {{ $t('poultry') }}
            </div>
          </div>

          <div class="home__products-item">
            <div class="home__products-image">
              <router-link :to="$i18nRoute({ name: 'Products' })">
                <ImageApp
                    :path="'beef.png'"
                    :alt="$t('beef')"
                />
              </router-link>
            </div>

            <div class="home__products-text">
              <div class="home__products-icon">
                <span class="icon icon__beef"></span>
              </div>
              {{ $t('beef') }}
            </div>
          </div>

          <div class="home__products-item">
            <div class="home__products-image">
              <router-link :to="$i18nRoute({ name: 'Products' })">
                <ImageApp
                    :path="'pork.png'"
                    :alt="$t('pork')"
                />
              </router-link>
            </div>

            <div class="home__products-text">
              <div class="home__products-icon">
                <span class="icon icon__pork"></span>
              </div>
              {{ $t('pork') }}
            </div>
          </div>
        </div>

        <div class="home__products-button" v-on:click="modalWindow = !modalWindow">{{
            $t('pages.home.products.button')
          }}
        </div>
      </div>
    </section>

    <PopUp
        v-if="modalWindow"
        v-on:closeModal="closeModal($event)"
        v-on:getLeadData="sendMail($event)"
    />
  </div>
</template>

<script>
import ImageApp from "@/components/ImageApp";
import PopUp from "@/components/PopUp";
import axios from "axios";

export default {
  name: "Home",
  components: {ImageApp, PopUp},
  data: function () {
    return {
      modalWindow: false
    }
  },
  methods: {
    sendMail: function (data) {
      console.log(data)
      axios.post('/mail.php', {
        name: data.name,
        phone: data.phone,
        email: data.email,
        text: data.text
      })
          .then(function (response) {
            console.log(response);
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    closeModal: function (data) {
      this.modalWindow = data;
    }
  }
}
</script>

<style lang="scss">
.home {

  &__background-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background-color: rgba(255, 255, 255, .5);
    }


    & video {

      @media (min-width: 1920px) {
        width: 100%;
      }
    }
  }

  &__welcome {
    position: relative;
    overflow: hidden;
    padding: 175px 0 85px;

    @media (min-width: 768px) {
      padding-top: 200px;
      min-height: 1000px;
    }



    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 78px;
      left: 0;
      bottom: 0;
      background: url("../assets/image/background__home_after.png") no-repeat center;
      background-size: cover;
      z-index: 1;
    }

    &-container {
      position: relative;
      z-index: 2;
    }

    &-top {
      display: flex;
      flex-direction: column;
      min-height: 325px;

      @media (min-width: 1200px) {
        position: relative;
        display: block;
      }

      & h1 {
        font: 900 30px "Lato-Black", sans-serif;
        color: #ff3a37;
        margin-bottom: 10px;
        text-align: center;

        @media (min-width: 550px) {
          font: 900 40px "Lato-Black", sans-serif;

        }

        @media (min-width: 768px) {
          font: 900 50px "Lato-Black", sans-serif;
          text-align: left;
        }

        @media (min-width: 1024px) {
          font: 900 60px "Lato-Black", sans-serif;
        }

        @media (min-width: 1200px) {
          text-align: left;
          font: 900 80px "Lato-Black", sans-serif;
        }
      }

      & p {
        font: 400 20px "Lato-Regular", sans-serif;
        color: #000;
        text-align: center;

        @media (min-width: 550px) {
          font: 400 22px "Lato-Regular", sans-serif;
        }

        @media (min-width: 768px) {
          font: 400 24px "Lato-Regular", sans-serif;
          text-align: left;
          max-width: 500px;
        }

        @media (min-width: 1024px) {
          font: 400 28px "Lato-Regular", sans-serif;
          max-width: 550px;
        }

        @media (min-width: 1200px) {
          font: 400 36px "Lato-Regular", sans-serif;
          max-width: 635px;
        }
      }

      &__image {
        width: 50%;
        margin: 20px auto;

        @media (min-width: 768px) {
          position: absolute;
          right: 0;
          top: 15px;
          width: 300px;
          z-index: -1;
        }

        @media (min-width: 1024px) {
          right: 0;
          top: 25px;
          width: 396px;
          z-index: -1;
        }
      }
    }

    &-bottom {
      display: flex;
      flex-direction: column;

      @media (min-width: 768px) {
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
      }

      &__image {
        margin: 0 auto 20px;
        width: 50%;
        display: none;

        @media (min-width: 768px) {
          width: 350px;
          margin: 0;
          display: block;
        }

        @media (min-width: 1024px) {
          width: 569px;
        }
      }

      &__block {
        display: flex;
        flex-direction: column;
        align-items: center;

        @media (min-width: 768px) {
          margin-left: 40px;
        }

        @media (min-width: 1024px) {
          max-width: 455px;
        }

        & p {
          color: #000;
          margin-bottom: 32px;
          font: 400 20px "Lato-Regular", sans-serif;
          letter-spacing: 0.8px;
          text-align: center;

          @media (min-width: 768px) {
            max-width: 485px;
            font: 400 23px "Lato-Regular", sans-serif;
            text-align: right;
          }

          @media (min-width: 1200px) {
            font: 400 34px "Lato-Regular", sans-serif;
            text-align: right;
          }
        }
      }
    }

    &-button {
      width: 100%;
      height: 80px;
      font: 900 25px/80px "Lato-Black", sans-serif;
      background-color: #3bc35e;
      border-radius: 10px;
      color: #fff;
      text-align: center;
      margin-bottom: 35px;
      position: relative;
      cursor: pointer;
      text-transform: uppercase;

      @media (min-width: 768px) {
        height: 93px;
        font: 900 36px/93px "Lato-Black", sans-serif;
      }

      &:after {
        content: "";
        position: absolute;
        left: -2px;
        top: 6px;
        width: calc(100% + 11px);
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: -1;
        border-radius: 10px;
        transition: all .3s ease-in-out;
      }

      &:hover:after {
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
      }
    }
  }

  &__information {
    background-color: #fff;
    padding: 30px 0 130px;
    position: relative;
    overflow: hidden;

    &-container {
      position: relative;
      z-index: 1;
    }

    &-list {
      display: grid;
      grid-gap: 40px;
      grid-template-columns: 1fr;

      @media (min-width: 1200px) {
        grid-gap: 93px;
      }
    }

    &-item {
      display: flex;
      flex-direction: column-reverse;

      @media (min-width: 768px) {
        flex-direction: row;
        justify-content: space-between;
      }
    }

    &-text {
      max-width: 100%;

      @media (min-width: 768px) {
        max-width: 350px;
        margin-right: 40px;
      }

      @media (min-width: 1024px) {
        max-width: 415px;
        margin-left: 65px;
      }

      @media (min-width: 1200px) {
      }

      & h3 {
        font: 700 20px "Lato-Bold", sans-serif;
        text-transform: uppercase;
        color: #000;
        margin: 15px 0;

        @media (min-width: 550px) {
          font: 700 22px "Lato-Bold", sans-serif;
        }

        @media (min-width: 768px) {
          font: 700 26px "Lato-Bold", sans-serif;
        }

        @media (min-width: 1024px) {
          font: 700 30px "Lato-Bold", sans-serif;
        }

        @media (min-width: 1200px) {
          font: 700 36px "Lato-Bold", sans-serif;
          margin-bottom: 15px;
        }
      }

      & p {
        font: 400 18px "Lato-Regular", sans-serif;
        color: #000;

        @media (min-width: 550px) {
          font: 400 20px "Lato-Regular", sans-serif;
        }

        @media (min-width: 768px) {
          font: 400 24px "Lato-Regular", sans-serif;
        }

        @media (min-width: 1024px) {
          font: 400 28px "Lato-Regular", sans-serif;
        }

        @media (min-width: 1200px) {
          font: 400 36px "Lato-Regular", sans-serif;
        }

        & span {
          display: block;
        }
      }
    }

    &-item:nth-child(2n) {
      flex-direction: column-reverse;

      @media (min-width: 768px) {
        flex-direction: row-reverse;
      }

      & .home__information-text {

        @media (min-width: 768px) {
          max-width: 370px;
          margin-left: 40px;
          margin-right: 0;
          text-align: right;
        }

        @media (min-width: 1024px) {
          max-width: 545px;
          text-align: right;
          margin-left: 0;
        }

        & h3 {
          margin-bottom: 0;
        }
      }
    }

    &-image {
      display: flex;
      justify-content: center;
      align-items: baseline;
      width: 100%;

      @media (min-width: 1200px) {
        width: 550px;
      }
    }

    &-background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      &__one {
        position: absolute;
        left: 10px;
        top: 10px;
        width: 60px;

        @media (min-width: 1200px) {
          left: 130px;
          top: 120px;
          width: 78px;
        }
      }

      &__two {
        position: absolute;
        left: calc((100% - 320px) / 2);
        top: 350px;
        width: 320px;

        @media (min-width: 550px) {
          left: calc((100% - 410px) / 2);
          top: 410px;
          width: 410px;
        }

        @media (min-width: 1200px) {
          left: calc((100% - 470px) / 2);
          top: 330px;
          width: 530px;
        }
      }

      &__three {
        position: absolute;
        right: 10px;
        top: 230px;
        width: 70px;

        @media (min-width: 1200px) {
          right: 50px;
          top: 270px;
          width: 161px;
        }
      }

      &__four {
        position: absolute;
        right: 30px;
        top: 650px;
        width: 60px;

        @media (min-width: 1200px) {
          left: 80px;
          top: 560px;
          width: 101px;
        }
      }

      &__five {
        position: absolute;
        left: -50px;
        bottom: -50px;
        width: 113px;

        @media (min-width: 1200px) {
          left: calc((100% - 145px) / 2);
          bottom: 10px;
          width: 113px;
        }
      }
    }
  }

  &__products {
    min-height: 742px;
    background: url("../assets/image/background__products.png") no-repeat center;
    background-size: cover;
    padding: 120px 20px 20px;

    &-container {
      max-width: 915px;
      min-height: 585px;
      border-radius: 10px;
      background-color: rgba(255, 255, 255, .7);
      padding: 22px;
      position: relative;
      z-index: 1;

      & h2 {
        font: 700 28px "Lato-Bold", sans-serif;
        color: #000;
        text-align: center;
        text-transform: uppercase;

        @media (min-width: 1200px) {
          font: 700 36px "Lato-Bold", sans-serif;
        }
      }
    }

    &-list {
      max-width: 810px;
      margin: 23px auto 30px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
    }

    &-item {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 30px;

      @media (min-width: 550px) {
        width: calc((100% - 30px) / 2);
      }

      @media (min-width: 768px) {
        width: calc((100% - 30px) / 3);
        margin-bottom: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    &-text {
      display: flex;
      align-items: center;
      margin-top: 30px;
      font: 400 25px "Lato-Regular", sans-serif;
      color: #000;
      text-transform: uppercase;

      @media (min-width: 1200px) {
        font: 40 30px "Lato-Bold", sans-serif;
      }
    }

    &-icon {
      width: 51px;
      height: 51px;
      border: 1px solid #000;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 15px;
    }

    &-image {
      display: flex;
      justify-content: center;
      width: 100%;
      height: 249px;
    }

    &-button {
      max-width: 750px;
      width: 100%;
      height: 60px;
      background-color: #3bc35e;
      border-radius: 10px;
      font: 900 25px/60px "Lato-Black", sans-serif;
      color: #fff;
      text-align: center;
      margin: 0 auto;
      position: relative;
      cursor: pointer;
      text-transform: uppercase;

      @media (min-width: 1200px) {
        max-width: 750px;
        height: 93px;
        font: 900 36px/93px "Lato-Black", sans-serif;
      }

      &:after {
        content: "";
        position: absolute;
        left: -8px;
        top: 2px;
        width: calc(100% + 13px);
        height: calc(100% + 5px);
        background-color: rgba(0, 0, 0, 0.5);
        z-index: -1;
        border-radius: 10px;
        transition: all 0.3s ease-in-out;
      }

      &:hover:after {
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
      }
    }
  }
}
</style>
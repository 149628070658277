<template>
  <div class="popup__wrapper">
    <div class="popup__block" v-if="!modalSuccess">
      <form @submit.prevent="serializeForm">
        <label for="form-name" :class="{ 'error': $v.lead.name.$error }">
          <input type="text" name="name" :placeholder="$t('components.modalWindow.form.name.placeholder')"
                 id="form-name" v-model.trim="$v.lead.name.$model">

          <span class="error"
                v-if="!$v.lead.name.required">{{ $t('components.modalWindow.form.name.error-message') }}</span>
        </label>

        <label for="form-phone" :class="{ 'error': $v.lead.phone.$error }">
          <vue-tel-input
              v-model.trim="$v.lead.phone.$model"
              :inputOptions="options"
              :valid-characters-only="true"
              :defaultCountry="'RU'"
              v-bind="phonePlaceholder($t('components.modalWindow.form.phone.placeholder'))"
          />

          <span class="error" v-if="!$v.lead.phone.required">{{$t('components.modalWindow.form.phone.error-message') }}</span>
        </label>

        <label for="form-email" :class="{ 'error': $v.lead.email.$error }">
          <input type="email" name="email" :placeholder="$t('components.modalWindow.form.email.placeholder')"
                 id="form-email" v-model.trim="$v.lead.email.$model">

          <span class="error" v-if="!$v.lead.email.required">{{
              $t('components.modalWindow.form.email.error-message.required')
            }}</span>
          <span class="error"
                v-if="!$v.lead.email.email">{{ $t('components.modalWindow.form.email.error-message.correct') }}</span>
        </label>

        <label for="form-text" :class="{ 'error': $v.lead.text.$error }">
          <textarea name="text" id="form-text" v-model.trim="$v.lead.text.$model"
                    :placeholder="$t('components.modalWindow.form.text.placeholder')"></textarea>

          <span class="error" v-if="!$v.lead.text.required">{{
              $t('components.modalWindow.form.text.error-message')
            }}</span>
        </label>

        <button type="submit">{{ $t('components.modalWindow.form.button') }}</button>
      </form>

      <span class="icon icon__close popup__close" v-on:click="close"></span>
    </div>

    <div class="popup__success" v-else-if="modalSuccess">
      <h2>{{ $t('components.modalWindow.success.title') }}</h2>
      <p>{{ $t('components.modalWindow.success.paragraph') }}</p>
      <span class="icon icon__close popup__close" v-on:click="close"></span>
    </div>
  </div>
</template>

<script>
import {required, email} from 'vuelidate/lib/validators'

export default {
  name: "PopUp",
  data: function () {
    return {
      lead: {
        name: '',
        phone: '',
        email: '',
        text: '',
      },
      submitStatus: null,
      modalSuccess: false,
      options: {
        placeholder: '',
        required: false,
        type: 'tel',
        name: 'phone'
      },
    }
  },
  validations() {
    return {
      lead: {
        name: {
          required,
        },
        email: {
          required,
          email
        },
        phone: {

        },
        text: {
          required,
        }
      }
    }
  },
  methods: {
    serializeForm: function () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        console.log(this.$v.$error)
      } else {
        // do your submit logic here
        this.$emit('getLeadData', this.lead);
        this.modalSuccess = true;
        setTimeout(() => {
          this.$emit('closeModal', false);
        }, 10000)
      }
    },
    close: function () {
      this.$emit('closeModal', false);
    },
    phonePlaceholder: function (text) {
      this.options.placeholder = text;
    }
  }
}
</script>

<style lang="scss">
.popup__wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.popup__block {
  position: relative;
  max-width: 555px;
  width: 100%;

  border-radius: 10px;
  background: url("../assets/image/popup__block.jpg") no-repeat center;
  background-size: cover;
  padding: 40px 20px;

  @media (min-width: 768px) {
    padding: 30px 65px;
    background-size: 120%;
    min-height: 555px;
  }

  & form {
    display: flex;
    flex-direction: column;
    width: 100%;

    & .vue-tel-input {
      border: 0;
      outline: none;
      box-shadow: none;
    }

    & .vti__dropdown-list {
      color: #000;
    }

    & .vti__dropdown {
      border-radius: 10px 0 0 10px;
      background-color: transparent;
      transition: background-color .3s ease-in-out;
    }

    & .vti__dropdown.open, .vti__dropdown:hover {
      background-color: #f3f3f3;
    }

    & label {
      width: 100%;
      min-height: 50px;
      border-radius: 10px;
      border: 1px solid transparent;
      transition: border-color .3s ease-in-out;
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      background-color: rgba(255, 255, 255, .8);

      @media (min-width: 768px) {
        margin-bottom: 40px;
      }

      &.error {
        border-color: #ff2e2b;
      }

      & span.error {
        display: none;
        font: 400 11px "Lato-Regular", sans-serif;
        background-color: #ff2e2b;
        color: #fff;
        padding: 3px;
        border-radius: 0 0 10px 10px;
      }

      &.error span {
        display: flex;
      }

      & input {
        width: 100%;
        height: 50px;
        border-radius: 10px;
        outline: none;
        border: 0;
        padding: 0 0 0 20px;
        background-color: transparent;
        font: 400 16px "Lato-Regular", sans-serif;
        color: #000;

        &::placeholder {
          font: 400 16px "Lato-Regular", sans-serif;
          color: #000;
        }
      }

      & textarea {
        width: 100%;
        height: 128px;
        border-radius: 10px;
        outline: none;
        border: 0;
        background-color: transparent;
        padding: 20px;
        font: 400 16px "Lato-Regular", sans-serif;
        color: #000;
        resize: none;

        &::placeholder {
          font: 400 16px "Lato-Regular", sans-serif;
          color: #000;
        }
      }

      &.error {
        & input,
        & textarea {
          border-radius: 10px 10px 0 0;
        }
      }
    }

    & button {
      width: 100%;
      height: 50px;
      border-radius: 10px;
      background-color: #3bc35e;
      outline: none;
      border: 0;
      text-align: center;
      font: 700 20px/50px "Lato-Bold", sans-serif;
      color: #fff;
      text-transform: uppercase;
      cursor: pointer;
    }
  }
}

.popup__success {
  max-width: 488px;
  width: 100%;
  min-height: 245px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: url("../assets/image/popup__success.jpg") no-repeat center;
  background-size: cover;
  position: relative;

  & h2 {
    font: 700 40px "Lato-Bold", sans-serif;
    color: #fff;
    text-align: center;

    @media (min-width: 768px) {
      font: 700 48px "Lato-Bold", sans-serif;
    }
  }

  & p {
    max-width: 250px;
    font: 700 20px "Lato-Bold", sans-serif;
    color: #fff;
    text-align: center;

    @media (min-width: 768px) {
      font: 700 30px "Lato-Bold", sans-serif;
      max-width: 340px;
    }
  }
}

.popup__close {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
}
</style>